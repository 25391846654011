import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ITractor } from 'src/app/models/ITractor.model';
import { APIResultState } from 'src/app/models/APIResultState.model';
import { pluck, map, tap, catchError } from 'rxjs/operators';
import { IMaintenance } from 'src/app/models/IMaintenance.model';
import { ITractorDetails } from 'src/app/models/ITractorDetail.model';
import { IRecallCampaign, IRecallCampaignDetails } from 'src/app/models/IRecallCampaign.model';
import { IGuarantee } from 'src/app/models/IGuarantee.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyTractorsService {

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  private _myTractors = new BehaviorSubject<{ tractors: ITractor[], state: APIResultState }>({ tractors: [], state: APIResultState.loading });

  defaultImageUrl = "assets/imgs/defaulttractor.png";

  myCurrentTractors() {
    return this._myTractors.value;
  }

  init() {
    this.getUserTractor().pipe(
      pluck('product'),
      map((tractors: any) => tractors.map(tractor => this.mapTractor(tractor))),
    ).subscribe(
      (tractors: ITractor[]) => {
        this._myTractors.next({ tractors, state: APIResultState.loaded });
      },
      err => {
        this._myTractors.next({ tractors: [], state: APIResultState.error })
      }
    );
  }

  mapTractor(data) {
    return <ITractor>{
      id: data._id,
      vin: data.vin,
      commercial_family: data.commercial_family,
      picture_name: data.picture_name ? `${environment.apiUrl}/${data.picture_name}` : this.defaultImageUrl,
      product_code: data.product_code,
      product_family_catalog: data.product_family_catalog,
      tech_name: data.tech_name
    }
  }

  mapTractorAndVin(data, vin) {
    return <ITractor>{
      id: data._id,
      vin,
      commercial_family: data.commercial_family,
      picture_name: data.picture_name ? `${environment.apiUrl}/${data.picture_name}` : this.defaultImageUrl,
      product_code: data.product_code,
      product_family_catalog: data.product_family_catalog,
      tech_name: data.tech_name
    }
  }

  mapMaintenanceDetail(data) {
    return <IMaintenance>{
      maintainance_code: data.maintainance_code,
      title: data.title
    }
  }

  mapRecallCampaigns(rc) {
    return <IRecallCampaign>{
      _id: rc._id,
      campaign_id: rc.campaign_id,
      description: rc.description,
      name: rc.name
    }
  }

  get myTractors() {
    this.init();
    return this._myTractors.asObservable(); 
  }

  getUserTractor() {
    return this.http.get(`${environment.apiUrl}/api/v1/product/mytractor`)
  }

  getTractorMaintenanceDetail(product_code, vin) {
    return this.http.get<{ product: ITractor, maintainances: IMaintenance[], vin: string }>(`${environment.apiUrl}/api/v1/product/${product_code}/${vin}`).pipe(
      map((details) => {
        return {
          maintainances: details.maintainances ? details.maintainances.map(maintenanceData => this.mapMaintenanceDetail(maintenanceData)) : [],
          product: details.product ? this.mapTractorAndVin(details.product, details.vin) : null
        }
      })
    )
  }

  getTractorDetail(product_code, vin) {
    return combineLatest(
      this.getTractorMaintenanceDetail(product_code, vin),
      this.getRecallCampaigns(vin)
    ).pipe(
      map(([maintanceDetails, recallCampaigns]) => {
        return <ITractorDetails>{
          maintainances: maintanceDetails.maintainances,
          product: maintanceDetails.product,
          recallCampaigns: recallCampaigns
        }
      })
    )
  }
  deleteTractor(vin) {
    return this.http.post(`${environment.apiUrl}/api/v1/product/delete/${vin}/`, {});
  }
  checkVinForUser(vin) {
    return this.http.get(`${environment.apiUrl}/api/v1/users/check/vin/${vin}`);
  }
  updateTractor(vin) {
    return this.http.get(`${environment.apiUrl}/api/v1/users/add/tractor/${vin}`);
  }

  addProduct(product_code, vin) {
    return this.http.post(`${environment.apiUrl}/api/v1/vin/`, { product_code, vin });
  }

  getMaintenanceDetail(maintenance_code) {
    return this.http.get(`${environment.apiUrl}/api/v1/product/maintainance/detail/${maintenance_code}`).pipe(
      pluck('mainainanceDetails')
    )
  }

  getRecallCampaigns(vin) {
    return this.http.get<{ recallCampaign: IRecallCampaign[] }>(`${environment.apiUrl}/api/v1/recallcampaign/frontend/${vin}`).pipe(
      pluck('recallCampaign'),
      map(recallCampaigns => recallCampaigns.map(rc => this.mapRecallCampaigns(rc)))
    )
  }
  getRecallCampaignDetail(id) {
    return this.http.get(`${environment.apiUrl}/api/v1/recallcampaign/frontend/detail/${id}`).pipe(
      pluck('campaign_detail'),

      map((res: any) => {
        console.log("respons e", res)
        return ({ name: res.name, description: res.description, image: `${environment.apiUrl}/${res.image}` } as IRecallCampaignDetails)
      })
    )
  }

  getDummyTractorPromotions(url) {
    return this.http.get(`${environment.apiUrl}/api/v1/${url}`);
  }

  getProductInfoByVin(vinNum) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/vin/product/${vinNum}`).pipe(
      pluck('product'),
      map((res) => {
        console.log("response of tractor", res);
        return {
          id: res._id,
          vin: vinNum,
          commercial_family: res.commercial_family,
          picture_name: res.picture_name ? `${environment.apiUrl}/${res.picture_name}` : this.defaultImageUrl,
          product_code: res.product_code,
          product_family_catalog: res.product_family_catalog,
          tech_name: res.tech_name
        } as ITractor
      }),
      tap((res) => console.log("aba ko tractor", res))
    )
  }

  getProductFromCommercialFamily(commercialFamilyName, vin) {
    return this.http.post<{ products: any[] }>(`${environment.apiUrl}/api/v1/product/frontend/`, { commercial_family: commercialFamilyName }).pipe(
      pluck('products'),
      map(products => {
        return products.map((product) => {
          return {
            id: product._id,
            commercial_family: product.commercial_family,
            product_code: product.product_code,
            product_family_catalog: product.product_family_catalog,
            tech_name: product.tech_name,
            picture_name: product.picture_name ? `${environment.apiUrl}/${product.picture_name}` : this.defaultImageUrl,
            vin: vin
          } as ITractor
        })
      }),
      catchError((err) => {
        console.log('error => ', err);
        return [];
      })
    )
  }

  getExtendedWarantyDetails(vin) {
    return this.http.get(`${environment.apiUrl}/api/v1/extendedwarranty/userdetails/get/${vin}`)
  }


  submitPhotos(images) {
    return this.http.post(`${environment.apiUrl}/api/v1/fileupload`, images)
  }

  submitExtendedWarrantyDetails(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/extendedwarranty/userdetails/create`, data)
  }

  editExtendedWarrantyDetails(id, data) {
    return this.http.post(`${environment.apiUrl}/api/v1/extendedwarranty/userdetails/update/${id}`, data)
  }

  getGuarantees(vin: string) {
    return this.http.get(`${environment.apiUrl}/api/v1/extendedwarranty/gurantee/get/${vin}`).pipe(
      map((res: any) => {
        return res.data.map((data, index) => {
          let label = this.translateService.instant("extended_warranty.guaranteePage.itemLabel") + (` #${index + 1}`)
          return <IGuarantee>{
            label,
            warranty_type: data.type,
            warranty_start_date: data.gwldt,
            month_of_validity: data.mm,
            hours_of_validity: data.hhhh,
            booklet_document: data.booklet_document ? `${environment.apiUrl}/${data.booklet_document}` : undefined,
            coverage_document: data.coverage_document ? `${environment.apiUrl}/${data.coverage_document}` : undefined,
            terms_condition_document: data.terms_condition_document ? `${environment.apiUrl}/${data.terms_condition_document}` : undefined
          }
        })
      })
    )
  }

  getDocumentations(vin: string) {
    return this.http.get(`${environment.apiUrl}/api/v1/extendedwarranty/document`).pipe(
      map((res: { documents: any[] }) => {
        console.log("data ===>>", res);
        return res.documents.map((item) => {
          if (item.image) {
            item.image = `${environment.apiUrl}/${item.image}`;
            return item;
          }
          return item;
        })

      })
    )
  }

  getDocumentationDetails(documentId) {
    return this.http.get(`${environment.apiUrl}/api/v1/extendedwarranty/document/get/detail/${documentId}`)
  }


}
