import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, pluck, switchMap, catchError, shareReplay, tap } from 'rxjs/operators';
import { IDealer } from 'src/app/models/IDealer.model';
import { Plugins } from '@capacitor/core';
import { from, EMPTY, throwError, of } from 'rxjs';
const { Geolocation } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  constructor(
    private http: HttpClient
  ) { }

  // getDealers(lat, lng) {
  //   return this.http.get(`${environment.apiUrl}/api/v1/dealerorganization/frontend/${lat}/${lng}`).pipe(
  //     pluck('dealers'),
  //     map((dealers: any[]) => dealers.map((dealer) => this.mapDealers(dealer))),
  //     catchError((err) => {
  //       return EMPTY;
  //     })
  //   )

  // }

  getDealersWithinMarket(countryId: string) {
    console.log('country Id ', countryId);
    if (countryId) {
      return this.http.get(`${environment.apiUrl}/api/v1/dealerorganization/frontend/country/${countryId}`).pipe(
        tap((res) => {
          console.log('response of dealers ==>>', res)
        }),
        pluck('dealers'),
        map((dealers: any[]) => dealers.map((dealer) => this.mapDealers(dealer))),
        catchError((err) => {
          return throwError(err)
        }),
        shareReplay(1)
      );
    }

  }

  // dealers$ = this.profileInfoService.userProfile.pipe(
  //   switchMap((res) => {
  //     if (res.state === APIResultState.loaded && res.profileInfo.country.id) {
  //       let country_id = res.profileInfo.country.id;
  //       return this.http.get(`${environment.apiUrl}/api/v1/dealerorganization/frontend/country/${country_id}`).pipe(
  //         tap((res) => {
  //           console.log('response of dealers ==>>', res)
  //         }),
  //         pluck('dealers'),
  //         map((dealers: any[]) => dealers.map((dealer) => this.mapDealers(dealer))),
  //         catchError((err) => {
  //           return throwError(err)
  //         }),
  //         shareReplay(1)
  //       )
  //     }
  //     return EMPTY;
  //   })
  // )

  getDealerListForPromotionDetail(promoId) {
    return from(Geolocation.getCurrentPosition()).pipe(
      switchMap((res) => {
        if (res.coords) {
          return this.getDealersForDummyCampaign(promoId, res.coords.latitude, res.coords.longitude).pipe(
            pluck('dealers'),
            map((dealers: any[]) => dealers.map((dealer) => this.mapDealers(dealer))),
            catchError((err) => throwError(err)),
            shareReplay(1)
          )
        }
        return EMPTY;
      }),
      catchError((err) => of([]))
    )
  }

  searchDealerEntries(countryId, term) {

    return this.http.get(`${environment.apiUrl}/api/v1/dealerorganization/search/country/${countryId}/${term}`)
      .pipe(
        pluck('dealers'),
        tap((res) => console.log('first', res)),
        map((dealers: any[]) => dealers.map((dealer) => this.mapDealers(dealer)))
      )



  }

  getFakeDealerId() {
    return this.http.get(`${environment.apiUrl}/api/v1/dealerorganization/dummy`).pipe(pluck("dealers", "_id"));
  }

  updateUserDealerById(userId: string, dealerId: string) {
    return this.http.post(`${environment.apiUrl}/api/v1/users/edit/${userId}`, { dealer: dealerId }).toPromise();
  }

  getDealersForDummyCampaign(campaign_id, lat, long) {
    return this.http.post(`${environment.apiUrl}/api/v1/dealer/dummy/${campaign_id}`, { lat, long })
  }


  mapDealers(data) {
    return <IDealer>{
      id: data._id,
      company_name: data.company_name,
      address: data.address,
      country: data.country,
      city: data.city,
      email: data.email,
      phone: data.phone,
      latitude: data.latitude,
      longitude: data.longitude,
      customer_no: data.customer_no
    }
  }


}
