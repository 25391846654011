import { Injectable } from '@angular/core';
import { IDealer } from 'src/app/models/IDealer.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapDataService {

  private _dealers = new BehaviorSubject<IDealer[]>([]);

  dealers = this._dealers.asObservable();

  updateDealers(dealers:IDealer[]) {
    console.log('UPDATE ..>>', dealers)
    this._dealers.next(dealers);
  }

  constructor(
  ) { }
}
