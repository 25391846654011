import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastAlertService {

  constructor(
    private toastCtrl:ToastController
  ) { }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'dark',
      duration: 3000
    });
    toast.present();
  }
}
