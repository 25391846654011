import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, shareReplay } from 'rxjs/operators';

const API_URL = environment.apiUrl;
const APP_ID = environment.app_id;
@Injectable({
  providedIn: 'root'
})
export class AppSetupService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getColors() {
    return this.http.get(`${API_URL}/api/v1/setup/${APP_ID}`).pipe(
      pluck('setup', '0'),
      shareReplay(1)
    )
  }

}
