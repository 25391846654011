import { Component, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { availableLanguages } from 'src/assets/i18n/language.constant';
const { Storage } = Plugins;

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {
  selectedLanguage = null;
  languages: any;

  constructor() { }

 async ngOnInit() {
    this.languages = availableLanguages;
    this.selectedLanguage = (await Storage.get({key: 'selectedLanguage'})).value;
    this.selectedLanguage =
      this.selectedLanguage === null ? "je" : this.selectedLanguage;
  }

  checkSelectedLanguage(code) {
    if (code === this.selectedLanguage) {
      return true;
    }
  }

  changelanguage($event) {
    Storage.set({key:"selectedLanguage", value:$event.target.value});
    window.location.reload();
  }

}
