import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguage } from 'src/assets/i18n/language.constant';
import { AppSetupService } from './services/common/appsetup.service';
import { environment } from 'src/environments/environment';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  StatusBarStyle,
  registerWebPlugin
} from "@capacitor/core";
import { StorageKey } from './models/StorageKey.model';
import { ThemeService } from './services/common/theme.service';
import { IAppSetupModel } from './models/IAppSetup.model';
import { Platform, AlertController, IonRouterOutlet, MenuController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NewsService } from './services/news/news.service';
import { TwrGinoService } from './services/gino/gino.service';
import { DealerPromotionService } from './services/dealer-promotion/dealer-promotion.service';
import { ListDealersPage } from './pages/my-dealer/list-dealers/list-dealers.page';
import { EMPTY, Observable, Subject } from 'rxjs';
import { TilesService } from './services/tiles/tiles.service';
import { AuthService } from './services/auth/auth.service';
import { concatMap, switchMap, tap} from 'rxjs/operators';
import { ProfileService } from './services/profile/profile.service';
import { TractorListService } from './pages/my-tractors/add-tractor/tractor-list.service';
import { AddTractorPage } from './pages/my-tractors/add-tractor/add-tractor.page';
import { APIResultState } from './models/APIResultState.model';
const { App, SplashScreen, StatusBar, PushNotifications, Storage, LocalNotifications, Browser } = Plugins;
import { ToastAlertService } from './services/common/toast-alert.service';
import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
const NO_COLOR_FOUND = "NO_COLOR_FOUND";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  datas : Subject<any> = new Subject<any>();

  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
  links$ : Observable<any> = this.tileService.tiles.pipe(
    tap(res => {
      // this.data.splice(0, 0, {name: 'dashboard'});
      if(res.state === APIResultState.loaded){
        this.datas.next(res);
      }
      this.data = res;
    })
  );
  appPagesInfo$: Observable<any>;
  selectedIndex = 0;
  selectedTIle = 'dashboard';
  data: any;

  constructor(
    private translateService: TranslateService,
    private appSetupService: AppSetupService,
    private themeService: ThemeService,
    private platform: Platform,
    private alertCtrl: AlertController,
    private router: Router,
    private sharedToastService: ToastAlertService,
    private twrGinoService: TwrGinoService,
    private newsService: NewsService,
    private dealerPromoService: DealerPromotionService,
    private tileService: TilesService,
    private menuCtrl: MenuController,
    private authService : AuthService,
    private profileService: ProfileService,
    private modalCtrl: ModalController,
    private tractorListService: TractorListService,
  ) {
    registerWebPlugin(FacebookLogin);
    this.initializeApp();
    this.datas.pipe(
      tap(res=>{
        this.data = res;
        this.componentAdded('');
      })
     ).subscribe();

    this.platform.backButton.subscribeWithPriority(1, async () => {
      console.log('hardware back button event detected');
      if (this.routerOutlet && this.routerOutlet.canGoBack()) {

        if (this.router.url === '/dashboard') {
          this.closeAppAlert();
        }
        else if (this.router.url === '/login') {
          return 'no-back-allowed'
        }

        else if (this.router.url === '/registration/valid-vin') {
          return 'no-back-allowed';
        }
        else if (this.router.url === '/registration/email-verification') {
          return 'no-back-allowed';
        }
        else if (this.router.url === '/forgot-password/success') {
          return 'no-back-allowed'
        }
        else if (this.router.url === '/btm-dashboard') {
          const stored_btm = await Storage.get({ key: StorageKey.btm_device });
          if (stored_btm.value) {
            let storedBtm = JSON.parse(stored_btm.value);
            let serial = storedBtm.serial;
            try {
              let result = await Promise.all([
                this.twrGinoService.unsubscribeFromProperty('CAN0.EC_EEC1.Engine_Speed'),
                this.twrGinoService.unsubscribeFromProperty('CAN0.EC_Fuel_Economy.Fuel_Rate'),
                this.twrGinoService.unsubscribeFromProperty('CAN0.EC_EEC2.Relative_Engine_Torque'),
                this.twrGinoService.unsubscribeFromProperty('CAN0.EC_Engine_Temperature.Coolant_temperature'),
                this.twrGinoService.unsubscribeFromProperty('CAN0.EC_EngHours.EngineHours')
              ]);
              console.log('result from unsubcribeToAllProperties', result)
            } catch (e) {
              console.log('error from unsubcribeToAllProperties', e)
            }
            try {
              await this.twrGinoService.disconnectFromGino(serial);
            } catch (e) {
              console.log('error from disconnect', e)
            }
          }
        }
        else {
          this.routerOutlet.pop();
        }

      } else if (this.router.url === '/dashboard') {
        this.closeAppAlert();
      }
    })
  }

  async initializeApp() {
    StatusBar.setStyle({ style: StatusBarStyle.Light })

    this.setUpLocalization();
    this.getSidebarInfo();
    this.profileService.getUserProfile();
    console.info(environment.apiUrl);

   this.authService.isLoggedIn().pipe(
       switchMap((loggedIn) => {
         if (loggedIn) {
           this.menuCtrl.enable(true);
           return EMPTY;
         }
         this.menuCtrl.enable(false);
         return EMPTY;
       })
     ).subscribe();

    App.addListener('appUrlOpen', (data) => {
      console.log('another app is calling this app with url ', data.url);
      //if app want's to navigate to login page navigate them with router.
    })

    try {
      let appColors;

      appColors = await this.fetchColors();

      if (appColors === NO_COLOR_FOUND) {
        const storedColorResponse = await this.checkStoredColors();
        if (storedColorResponse === NO_COLOR_FOUND) {
          let message = this.translateService.instant("shared.internetDisconnectivity");
          this.themeService.setDefaultColor();
          await SplashScreen.hide();
          return this.sharedToastService.presentToast(message);
        }
        appColors = storedColorResponse;
      }

      await this.themeService.setTheme(appColors);
      await SplashScreen.hide();
      if (this.platform.is("ios") || this.platform.is('android')) {

        await LocalNotifications.requestPermission();

        const result = await PushNotifications.requestPermission();
        if (result.granted) {
          await PushNotifications.register();
        }
        await PushNotifications.removeAllDeliveredNotifications();
      }

    } catch (e) {
      console.log(e)
    }

    if (this.platform.is('android') || this.platform.is('ios')) {
      PushNotifications.addListener('registration',
        async (token: PushNotificationToken) => {
          try {
            let storageSetResult = await Storage.set({ key: StorageKey.notification_token, value: token.value });
            return storageSetResult;
          } catch (e) {
            console.error(e)
          }
        }
      );

      PushNotifications.addListener('registrationError', (err) => {
        console.error(err)
      })



      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
        if (this.platform.is('android')) {
          this.showCustomNotification(notification.id, notification.title, notification.body, notification.data);
        }
      })

      PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {

        if (notification.actionId === 'tap') {

          let access_token = await Storage.get({ key: StorageKey.accessToken });
          if (access_token.value) {

            let custom_notification = notification.notification.data;
            if (custom_notification.notification_type === 'custom') {
              if (custom_notification.type === 'promotion') {
                this.dealerPromoService.init();
                return this.router.navigateByUrl('/promotions');
              }
              if (custom_notification.type === 'news') {
                this.newsService.init();
                return this.router.navigateByUrl('/news');
              }
              if (custom_notification.type === 'survey') {
                return this.router.navigateByUrl('/survey')
              }
              if (custom_notification.type === 'my_tractor') {
                return this.router.navigateByUrl('/mytractors')
              }

              if (custom_notification.type === 'my_dealer') {
                return this.router.navigateByUrl('/mydealers')
              }
            } else {
              if (custom_notification.type === 'news') {
                this.newsService.init();
                return this.router.navigateByUrl('/dashboard').then(() => {
                  return this.router.navigateByUrl(`/news/${custom_notification.news_id}`);
                })
              }
              if (custom_notification.type === 'campaign') {
                this.dealerPromoService.init();
                return this.router.navigateByUrl('/dashboard').then(() => {
                  return this.router.navigateByUrl(`/promo-details/${custom_notification.campaign_id}`)
                })
              }
            }

          }
          return this.router.navigateByUrl('/dashboard');
        }
      })

      LocalNotifications.addListener('localNotificationActionPerformed', async (notification) => {

        try {

          let access_token = await Storage.get({ key: StorageKey.accessToken });
          if (access_token.value) {
            if (notification.notification.extra) {
              let custom_notification = notification.notification.extra;
              if (custom_notification.notification_type === 'custom') {
                if (custom_notification.type === 'promotion') {
                  return await this.router.navigateByUrl('/promotions');
                }
                if (custom_notification.type === 'news') {
                  return await this.router.navigateByUrl('/news');
                }
              } else {
                if (custom_notification.type === 'news') {
                  this.newsService.init();
                  return await this.router.navigateByUrl(`/news/${custom_notification.news_id}`);
                }

                if (custom_notification.type === 'campaign') {
                  return await this.router.navigateByUrl(`/promo-details/${custom_notification.campaign_id}`);
                }
              }

            } else {
              // console.log('not custom push notification');
            }
          }
          return await this.router.navigateByUrl('/dashboard');
        } catch (e) {
          console.error(e)
        }
      });
    }

  }

  async closeAppAlert() {
    const closeAlert = await this.alertCtrl.create({
      header: "Close app",
      message: "Do you really want to close the app?",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Close App',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });
    return await closeAlert.present();
  }


 async setUpLocalization() {
    this.translateService.setDefaultLang(defaultLanguage);
    const data = (await Storage.get({key: 'selectedLanguage'})).value;
    if(data){
     return this.translateService.use(data);
    }

    this.translateService.use(defaultLanguage);
    // let language = (navigator.language.indexOf('-') != -1) ? navigator.language.substring(0, navigator.language.indexOf('-')) : navigator.language;
    // this.translateService.use(language);
  }

  checkStoredColors() {
    return new Promise((resolve, reject) => {
      Storage.get({ key: StorageKey.appTheme }).then((colors) => {
        if (colors.value) {
          resolve(JSON.parse(colors.value))
        } else {
          resolve(NO_COLOR_FOUND);
        }
      })
    })
  }

  fetchColors() {
    return new Promise((resolve, reject) => {
      this.appSetupService.getColors().subscribe(
        (res: IAppSetupModel) => {
          if (res) {
            let setupValues = {
              primary: res.accent_color,
              background: res.background_color,
              textColor: res.text_color,
              toolbarBackgroundColor: res.primary_color,
              textColorPrimary: res.text_color_primary,
              app_name: res.app_name, app_logo: res.app_logo
            };
            return resolve(setupValues);
          }
          return resolve(NO_COLOR_FOUND);
        },
        err => {
          console.log('error ', err)
          return resolve(NO_COLOR_FOUND)
        }
      )
    })
  }

  async showCustomNotification(id: string, title: string, body: string, data: any) {

    try {
      const notifs = await LocalNotifications.schedule({
        notifications: [
          {
            title: title,
            body: body,
            id: 1,
            schedule: { at: new Date(Date.now() + 1000 * 5) },
            smallIcon: 'ic_notification',
            iconColor: "#cc0000",
            sound: 'beep.wav',
            attachments: null,
            actionTypeId: "",
            extra: data
          }
        ]
      });
      return notifs;
    } catch (e) { 
      console.log('something went wrong inside local notification', e)
    }

  }

  getSidebarInfo() {
    this.tileService.getAppTiles();
  }

  async redirectDetail(tileData) {
    console.log(tileData.name);
    const current_user_status = (await Storage.get({ key: StorageKey.user_status })).value;
    // console.log('current user status => ', current_user_status);

    let name = tileData.feature;

    if(tileData.name === 'dashboard'){
      this.router.navigateByUrl('/dashboard');
    }
    if(tileData.name === 'profile') {
      this.router.navigateByUrl('/profile');
    }

    if (name === "cms") {
      return this.openDeviceBrowser(tileData.value);
    }
    if (name === "myDealers") {
      if (current_user_status === "both" || current_user_status === "dealer") {
        let data = {
          key: "dealer",
          headerMessage: this.translateService.instant("shared.dealerPopup.messageLabel"),
          okayBtnText: this.translateService.instant("shared.dealerPopup.okBtnLabel"),
          noBtnText: this.translateService.instant("shared.dealerPopup.noBtnLabel")
        };
        return this.openPopUp(data);
      } else {
        this.directForwardToPage(tileData);
      }
    }

    if (name === "myTractors") {
      console.log("current user status ===> ", current_user_status);

      if (current_user_status === "both" || current_user_status === "vin") {
        let data = {
          key: "vin",
          headerMessage: this.translateService.instant("shared.myTractorsPopup.messageLabel"),
          okayBtnText: this.translateService.instant("shared.myTractorsPopup.okBtnLabel"),
          noBtnText: this.translateService.instant("shared.myTractorsPopup.noBtnLabel")
        };
        return this.openPopUp(data);
      } else {
        this.directForwardToPage(tileData);
      }
    }

    if (name === "promotions") {
      return this.directForwardToPage(tileData);
    }

    if (name === "survey") {
      return this.directForwardToPage(tileData);
    }

    if (name === "news") {
      return this.directForwardToPage(tileData);
    }

    if ((name === "tractor_promotion")) {
      return this.directForwardToPage(tileData);
    }

  }

  async openPopUp(params) {
    let alert = await this.alertCtrl.create({
      message: params.headerMessage,
      buttons: [
        {
          text: params.noBtnText,
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          }
        },
        {
          text: params.okayBtnText,
          handler: () => {
            if (params.key === "dealer") {
              this.selectDealer();
            }
            if (params.key === "vin") {
              return this.selectVin();
            }
          }
        }
      ]
    });
    return await alert.present();
  }

  async selectDealer() {
    const dealerChangeModal = await this.modalCtrl.create({
      component: ListDealersPage,
      componentProps: {
        pageSource: 'dashboard'
      },
      animated: true,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });
    await dealerChangeModal.present();
  }

  async selectVin() {
    this.tractorListService.clearTractorsInfo();
    const selectTractorModal = await this.modalCtrl.create({
      component: AddTractorPage,
      animated: true,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });
    await selectTractorModal.present();
  }

  directForwardToPage(tileData) {
    let name = tileData.feature;
    let pageName = this.convertToPageNameFormat(name);

    this.router.navigate([`/${pageName}`], {
      queryParams: { title: tileData.name }
    });

  }

  convertToPageNameFormat(name) {
    if (name && name !== name.toLowerCase()) {
      name = name.toLowerCase();
    }
    return name;
  }

  async openDeviceBrowser(id) {
    try {
      let link: any = await this.getLink(id);
      await Browser.open({ url: link });
    } catch (e) {
      console.log(e)
    }

  }

  getLink(id) {
    return new Promise((resolve, reject) => {
      this.tileService.getLink(id).subscribe(
        (res: any) => {
          return resolve(res.link);
        },
        err => {
          return reject(err);
        }
      );
    });
  }

   componentAdded($event){
    const data = this.router.url.split('title=')[1];
    if(this.router.url === '/dashboard'){
      this.selectedTIle = 'dashboard';
    }else if(this.router.url === '/profile') {
      this.selectedTIle = 'profile';
    }else if(this.router.url.includes('/mytractors')){
        this.selectedTIle = this.data.tiles.find(tiles => tiles.feature === 'myTractors').name;
    }else if(this.router.url.includes('/promo-details')){
      this.selectedTIle = this.data.tiles.find(tiles => tiles.feature === 'promotions').name;
    }else if(this.router.url.includes('/promotions')){
      this.selectedTIle = this.data.tiles.find(tiles => tiles.feature === 'promotions').name;
    }
    else if(this.router.url.includes('/survey')){
      this.selectedTIle = this.data.tiles.find(tiles=> tiles.feature === 'survey').name;
    }else if(this.router.url.includes('/tractor_promotion')){
      this.selectedTIle =this.data.tiles.find(tiles => tiles.feature === 'tractor_promotion').name;
    }else if(this.router.url.includes('/news')){
      this.selectedTIle =  this.data.tiles.find(tiles => tiles.feature === 'news').name;
    }
    else {
      if(data){
        this.selectedTIle = decodeURI(data).replace('%26', '&');
      }
    }
  // }
    // if ($event.router) {
    //   this.selectedTIle = $event.router.browserUrlTree.queryParams.title;
    // }else {
    //   this.selectedTIle = $event.title;
    // }
  }

  getIndex(data, index) {
    // console.log(data, index);
    if (data.name === this.selectedTIle){
      this.selectedIndex = index;
      return +index;
    }
  }

  async logOutPopup() {
    let alert = await this.alertCtrl.create({
      header: this.translateService.instant("profile.logoutConfirmBox.title"),
      message: this.translateService.instant("profile.logoutConfirmBox.message"),
      buttons: [
        {
          text: this.translateService.instant("profile.logoutConfirmBox.cancelBtn"),
          role: "cancel",
          handler: () => console.log("Cancel clicked")
        },
        {
          text: this.translateService.instant("profile.logoutConfirmBox.selectBtn"),
          handler: () => this.confirmLogout()
        }
      ]
    });
    return await alert.present();
  }
  confirmLogout() {
    this.profileService.clearUserProfile();
    this.authService.logout().then(_ => this.router.navigateByUrl('/login'))
  }
}
