import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageKey } from 'src/app/models/StorageKey.model';
import * as Color from 'color';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { SupportedApps } from 'src/app/models/SupportedApp.model';
const { Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  async setTheme(theme) {
    const cssText = CSSTextGenerator(theme);
    this.setGlobalCSS(cssText);
    try {
      return await Storage.set({ key: StorageKey.appTheme, value: JSON.stringify(theme) });
    } catch (e) {
      console.log('erorr while storing the values ', e);
    }
  }

  setThemeFromStorage(theme) {
    const cssText = CSSTextGenerator(theme);
    this.setGlobalCSS(cssText);
  }

  setDefaultColor() {
    let colors;
    if (environment.app_name === SupportedApps.mySAME) {
      colors = {
        primary: '#cc0000',
        background: '#ffffff',
        textColor: '#000000',
        textColorPrimary: '#fdfaf9',
        toolbarBackgroundColor: '#da130e'
      }
    }
    if (environment.app_name === SupportedApps.myDEUTZFAHR) {
      colors = {
        primary: '#7ab51d',
        background: '#ffffff',
        textColor: '#000000',
        textColorPrimary: '#ffffff',
        toolbarBackgroundColor: '#a8a8a8'
      }
    }

    const cssText = CSSTextGenerator(colors);
    this.setGlobalCSS(cssText);

  }

  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
    return;
  }



}
const defaults = {
  primary: '#cc0000',
  secondary: '#3dc2ff',
  tertiary: '#5260ff',
  success: '#2dd36f',
  warning: '#ffc409',
  danger: '#eb445a',
  dark: '#222428',
  medium: '#92949c',
  light: '#f4f5f8'
};

function CSSTextGenerator(colors) {

  colors = { ...defaults, ...colors };
  const {
    primary,
    secondary,
    tertiary,
    success,
    warning,
    danger,
    dark,
    medium,
    light,
    background,
    textColor,
    toolbarBackgroundColor,
    textColorPrimary
  } = colors;

  const shadeRatio = 0.1;
  const tintRatio = 0.9;

  return `
   
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 218,19,14;
    --ion-color-primary-contrast: ${contrast(primary)};
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint: ${Color(primary).lighten(tintRatio)};

    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${Color.rgb(secondary)};
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-rgb: ${Color.rgb(contrast(secondary))};
    --ion-color-secondary-shade: ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};

    --ion-color-tertiary: ${tertiary};
    --ion-color-tertiary-rgb: ${Color.rgb(tertiary)};
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: ${Color.rgb(contrast(tertiary))};
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint: ${Color(tertiary).lighten(tintRatio)};

    --ion-color-success: ${success};
    --ion-color-success-rgb: ${Color.rgb(success)};
    --ion-color-success-contrast: ${contrast(success)};
    --ion-color-success-contrast-rgb: ${Color.rgb(contrast(success))};
    --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio)};

    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${Color.rgb(warning)};
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: ${Color.rgb(contrast(success))};
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};

    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${Color.rgb(danger)};
    --ion-color-danger-contrast: ${contrast(danger)};
    --ion-color-danger-contrast-rgb: ${Color.rgb(contrast(danger))};
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};

    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${Color.rgb(dark)};
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-rgb: ${Color.rgb(contrast(dark))};
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};

    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${Color.rgb(medium)};
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-rgb: ${Color.rgb(contrast(success))};
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};

    --ion-color-light: ${light};
    --ion-color-light-rgb: ${Color.rgb(light)};
    --ion-color-light-contrast: ${contrast(light)};
    --ion-color-light-contrast-rgb: ${Color.rgb(contrast(light))};
    --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio)};

    --ion-background-color: ${background};
    --ion-text-color: ${textColor};
    --ion-toolbar-background: ${toolbarBackgroundColor};
    --ion-toolbar-color: ${textColorPrimary};
    --ion-item-border-color: ${primary};
    
  `;
}

function contrast(color, ratio = 10) {
  color = Color(color);
  return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
}