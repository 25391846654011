import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Plugins } from '@capacitor/core';
import { StorageKey } from 'src/app/models/StorageKey.model';
import { from, throwError } from 'rxjs';
import { switchMap, catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  public translate;

  constructor(
    public injector: Injector,
    private translateService: TranslateService
  ) { }
  intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    this.translate = this.injector.get(TranslateService);
    let promise = Storage.get({ key: StorageKey.accessToken });
    return from(promise).pipe(
      switchMap(accessToken => {
        let clonedReq = this.addToken(req, accessToken.value);
        return next.handle(clonedReq).pipe(
          retry(1),
          catchError((err: HttpErrorResponse) => {
            if (err.error instanceof ErrorEvent) {
              if (err.status === 401) {
                return throwError(err);
                // if (
                //   clonedReq.url.includes("login") ||
                //   clonedReq.url.includes("forgetpassword")
                // ) {
                //   return throwError(err);
                // }
                // return throwError(err);
              }

            } else {
              if (err.status === 0) {
                return throwError(0);
              }
              return throwError(err);
            }
            return throwError(err);
          })
        );
      })
    )
  }

  addToken(request, token) {
    return request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        app_id: environment.app_id,
        lang: this.translate.currentLang
      }
    });
  }
}
