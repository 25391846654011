import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITractor } from 'src/app/models/ITractor.model';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TractorListService {

  private _tractorList = new BehaviorSubject<{tractors: ITractor[], found:boolean}>({tractors:[], found: null});

  tractorsInfo = this._tractorList.asObservable();

  currentTractorsInfo = () => this._tractorList.value;

  updateTractorsInfo(tractorsInfo: {tractors:ITractor[], found: boolean}) {
    this._tractorList.next(tractorsInfo); 
  }

  clearTractorsInfo() {
    return this._tractorList.next(null);
  }
}
