import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddTractorPage } from './add-tractor.page';
import { TranslateModule } from '@ngx-translate/core';
import { VinHelpModule } from 'src/app/components/vin-help/vin-help.module';
import { CommercialFamilyPageModule } from './commercial-family/commercial-family.module';
import { CommercialFamilyPage } from './commercial-family/commercial-family.page';
import { ImageShellComponentModule } from 'src/app/components/image-shell/image-shell.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    VinHelpModule,
    TranslateModule,
    ImageShellComponentModule,
    CommercialFamilyPageModule
  ],
  declarations: [AddTractorPage],
  entryComponents: [CommercialFamilyPage]
})
export class AddTractorPageModule {}
