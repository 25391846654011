import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, combineLatest, EMPTY, throwError } from 'rxjs';
import { VinService } from 'src/app/services/vin/vin.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, startWith, map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commercial-family',
  templateUrl: './commercial-family.component.html',
  styleUrls: ['./commercial-family.component.scss'],
})
export class CommercialFamilyComponent implements OnInit{

  @Output() confirm = new EventEmitter<any>();
  errorMessage:string;
  searchTerm = new FormControl();

  searchTerm$: Observable<any> = this.searchTerm.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    startWith('')
  )

  commercial_families$:Observable<any> = this.vinService.getCommercialFamily().pipe(
    catchError((err) => {
      if(err === 0) {
        let message = this.translateService.instant("shared.internetDisconnectivity");
        this.errorMessage = message;
        return EMPTY;
      }
      return throwError(err);
    })
  );

  filtered_commercial_families$:Observable<any>;
  

  constructor(
    private vinService:VinService,
    private translateService:TranslateService
  ) { }

  ngOnInit(): void {
     this.filtered_commercial_families$ = combineLatest(
      this.commercial_families$,
      this.searchTerm$
    ).pipe(
      map(([commercialFamilies, term]) => {
        return commercialFamilies.filter(family => {
          return family.toLowerCase().indexOf(term.toLowerCase()) !== -1
        })
      })
    )
  }

  onConfirm(item) {
    this.confirm.emit(item);
  }

}
