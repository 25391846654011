import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { INews } from 'src/app/models/INews.model';
import { APIResultState } from 'src/app/models/APIResultState.model';
import { pluck, map, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  private _news = new BehaviorSubject<{ news: INews[], state: APIResultState }>({ news: [], state: APIResultState.loading });

  get news() {
    this.init();
    return this._news.asObservable();
  }

  getAllNews() {
    return this.http.get(`${environment.apiUrl}/api/v1/news/frontend`);
  }

  getDetailNews(newsId) {
    return this.http.get(`${environment.apiUrl}/api/v1/news/frontend/${newsId}`);
  }

  init() {
    console.log('intialize called of get news')
    this.getAllNews().pipe(
      pluck('news'),
      map((newsList: INews[]) => {
        return newsList.map((news) => {
          news.published_date = moment(news.published_date).format("DD-MM-YYYY");
          news.image = encodeURI(`${environment.apiUrl}/${news.image}`);
          // news.content = this.extractContent(news.content);
          return news;
        })
      })
    ).subscribe(
      news => {
        this._news.next({ news, state: APIResultState.loaded })
      },
      err => {
        this._news.next({ news: [], state: APIResultState.error })
      }
    );
  }

  extractContent(html) {
    console.log('html of new', html)
    return (new DOMParser).parseFromString(html, "text/html").
      documentElement.textContent;
  }

}
