import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { CommercialFamilyPage } from './commercial-family.page';
import { TranslateModule } from '@ngx-translate/core';
import { CommercialFamilyModule } from 'src/app/components/commercial-family/commercial-family.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CommercialFamilyModule,
    TranslateModule
  ],
  declarations: [CommercialFamilyPage]
})
export class CommercialFamilyPageModule {}
