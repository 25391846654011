import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { StorageKey } from 'src/app/models/StorageKey.model';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { TilesService } from '../tiles/tiles.service';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authSubject = new BehaviorSubject(null)

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private tileService: TilesService
  ) {
    this.ifLoggedIn();
  }

  async ifLoggedIn() {
    let access_token = await Storage.get({ key: StorageKey.accessToken });
    if (access_token.value) {
      console.info("access token", access_token.value);
      this.authSubject.next(true);
    } else {
      console.info("access token", access_token.value);
      this.authSubject.next(false);
    }
  }

  isAuthenticated() {
    return this.authSubject.value;
  }

  isLoggedIn() {
    return this.authSubject.asObservable().pipe(
      filter((res) => res != null)
    )
  }

  login(user: { email: string, password: string, notification_token: string }): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/auth/appuser/login`, user);
  }

  async refreshAccessToken() {
    try {
      let refresh_token = (await Storage.get({ key: StorageKey.refreshToken })).value;
      let data = await this.refreshToken(refresh_token);
      let storedTokens = await this.storeToken(data);
      return storedTokens;

    } catch (e) {
      console.error(e);
    }
  }

  refreshToken(token) {
    return new Promise((resolve, reject) => {
      return this.http.post<any>(`${environment.apiUrl}/api/v1/auth/app_user/refreshToken`, { 'refreshToken': token }).subscribe(res => {
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  async storeToken(data) {
    let allInfo = this.getDecodedAccessToken(data.token.access_token);

    let storeUserIdPromise = Storage.set({ key: StorageKey.userId, value: allInfo._id });
    let storeUserStatusPromise = Storage.set({ key: StorageKey.user_status, value: allInfo.user_status });

    let storeAccessToken = Storage.set({ key: StorageKey.accessToken, value: data.token.access_token });
    let storeRefreshToken = Storage.set({ key: StorageKey.refreshToken, value: data.token.refresh_token });

    return Promise.all([storeUserIdPromise, storeUserStatusPromise, storeAccessToken, storeRefreshToken]);
  }

  resetPassword(email) {
    return this.http.post(`${environment.apiUrl}/api/v1/auth/appuser/forgetpassword`, { email });
  }

  resetPasswordViaSMS(phone) {
    return this.http.post(`${environment.apiUrl}/api/v1/auth/appuser/forgetpassword`, { verification_type: 'sms', telephone_number: phone })
  }


  getDecodedAccessToken(token: string): any {
    if (token !== null) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      return JSON.parse(window.atob(base64));
    }
    return null;
  }

  setAuthSubject(value) {
    this.authSubject.next(value);
  }

  async logout() {
    this.authSubject.next(false);

    this.profileService.clearUserProfile();
    this.tileService.clearTiles();

    let storeUserIdPromise = Storage.remove({ key: StorageKey.userId });
    let storeUserStatusPromise = Storage.remove({ key: StorageKey.user_status });

    let storeAccessToken = Storage.remove({ key: StorageKey.accessToken });
    let storeRefreshToken = Storage.remove({ key: StorageKey.refreshToken });

    await Promise.all([storeUserIdPromise, storeUserStatusPromise, storeAccessToken, storeRefreshToken]);
  }

}
