import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';
import { MyTractorsService } from 'src/app/services/my-tractors/my-tractors.service';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TractorListService } from '../tractor-list.service';

@Component({
  selector: 'app-commercial-family-page',
  templateUrl: './commercial-family.page.html',
  styleUrls: ['./commercial-family.page.scss'],
})
export class CommercialFamilyPage implements OnInit {

  @Input() vin:string;

  constructor(
    private toastCtrl:ToastController,
    private modalCtrl:ModalController,
    private loadingCtrl:LoadingController,
    private myTractorService:MyTractorsService,
    private translateService:TranslateService,
    private tractorListService:TractorListService
  ) { }

  ngOnInit() {
  }
  
  async familySelected(name) {

    this.tractorListService.clearTractorsInfo();
    const loading = await this.loadingCtrl.create({ backdropDismiss: false });
    await loading.present();

    this.myTractorService.getProductFromCommercialFamily(name, this.vin).pipe(
      tap(async (tractors) => {
        await loading.dismiss();
        this.tractorListService.updateTractorsInfo({tractors, found: false})
        return this.modalCtrl.dismiss(null, 'confirm');
      }),
      catchError((err) => {
        console.log("errror -> ", err);
        return this.presentToast();
      })
    ).subscribe();

  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: this.translateService.instant("shared.zeroData"),
      color: 'dark',
      duration: 3000
    });
    toast.present();
  }

}
