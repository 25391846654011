import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';

const { TWRGino } = Plugins;
// declare const Capacitor: any;
// const { GinoPlugin } = Capacitor.Plugins;
declare let window: any;
interface IState {
    isInited: boolean;
}
interface ISubscriptionModel {
    result: {
        propertyValue: string;
        propertyName: string;
    }
}

const INITIAL_STATE: IState = {
    isInited: false
};

@Injectable({
    providedIn: 'root'
})
export class TwrGinoService {
    private subject: BehaviorSubject<IState> = new BehaviorSubject(
        INITIAL_STATE
    );

    constructor(
        private platform: Platform
    ) { }

    get value() {
        return this.subject.value;
    }

    initial_state = this.subject.asObservable();

    public watch<T>(name: string): Observable<T> {
        return this.subject
            .pipe(pluck<IState, T>(name))
            .pipe(distinctUntilChanged<T>());
    }

    public async set<T>(name: string, state: any): Promise<void> {
        try {
            this.subject.next({
                ...this.value,
                [name]: state
            });
        } catch (e) {
            console.error(e);
        }

        return;
    }

    /**
     * Init gino plugin
     */
    public init(DEVELOPER_KEY: string): Promise<any> {
        return new Promise((resolve, reject) => {
            window.cordova.plugins.TWRGino.init(
                DEVELOPER_KEY,
                result => {
                    this.set('isInited', true);
                    try {
                        // const response = JSON.parse(result);
                        // console.log('response from init >>', response)
                        resolve(result);
                    } catch (e) {
                        const response = result;
                        reject(response);
                    }
                },
                err => {
                    try {
                        console.error(err);
                        // const response = JSON.parse(err);
                        reject(err);
                    } catch (e) {
                        console.error(e);
                        reject(e);
                    }
                }
            );
        });
    }

    public startDiscovery(): Observable<any> {
        // tslint:disable-next-line: deprecation
        return Observable.create(observer => {
            window.cordova.plugins.TWRGino.startDiscovery(
                result => {
                    try {
                        // const response = JSON.parse(result);

                        observer.next(result);
                    } catch (e) {
                        const response = result;
                        observer.next(response);
                    }
                },
                err => {
                    try {
                        console.error(err);
                        // const response = JSON.parse(err);
                        observer.error(err);
                    } catch (e) {
                        console.error(e);
                        observer.error(e);
                    }
                }
            );
        });
    }

    public stopDiscovery(): Promise<any> {
        return new Promise((resolve, reject) => {
            window.cordova.plugins.TWRGino.stopDiscovery(
                result => {
                    try {
                        // const response = JSON.parse(result);
                        resolve(result);
                    } catch (e) {
                        const response = result;
                        resolve(response);
                    }
                },
                err => {
                    console.error(err);
                    try {
                        // const response = JSON.parse(err);
                        reject(err);
                    } catch (e) {
                        const response = err;
                        reject(response);
                    }
                }
            );
        });
    }

    /**
     * Funzione di connessione ad uno specifico device Gino
     * @param ginoSerial Seriale device Gino
     */
    public connectToGino(ginoSerial: string): Promise<any> {
        return new Promise((resolve, reject) => {
            window.cordova.plugins.TWRGino.connectToGino(
                ginoSerial,
                result => {
                    try {
                        // console.log('after json parse', JSON.parse())
                        // const response = JSON.parse(result);
                        resolve(result);
                    } catch (e) {
                        const response = result;
                        resolve(response);
                    }
                },
                err => {
                    console.error(err);
                    try {
                        // const response = JSON.parse(err);
                        reject(err);
                    } catch (e) {
                        const response = err;
                        reject(response);
                    }
                }
            );
        });
    }

    public disconnectFromGino(ginoSerial: string): Promise<any> {
        return new Promise((resolve, reject) => {
            window.cordova.plugins.TWRGino.disconnectFromGino(
                ginoSerial,
                result => {
                    try {
                        console.log(result);
                        let response: any;
                        if (this.platform.is('android')) {
                            response = JSON.parse(result);
                            return resolve(response)
                        } else {
                            response = result
                            return resolve(response);
                        }
                    } catch (e) {
                        let response: any;
                        if (this.platform.is('android')) {
                            response = JSON.parse(result);
                        }
                        response = result
                        resolve(response);
                    }
                },
                err => {
                    console.error(err);
                    try {
                        // const response = JSON.parse(err);
                        reject(err);
                    } catch (e) {
                        const response = err;
                        reject(response);
                    }
                }
            );
        });
    }

    public subscribeToProperty(property: string): Observable<any> {
        // tslint:disable-next-line: deprecation
        return Observable.create(observer => {
            window.cordova.plugins.TWRGino.subscribeToProperty(
                property,
                response => {
                    try {

                        // const response = JSON.parse(result);
                        if (this.platform.is('android')) {
                            response = JSON.parse(response);

                            let transformedData: ISubscriptionModel = {
                                result: {
                                    propertyName: response.result.property,
                                    propertyValue: response.result.stringValue
                                }
                            }
                            response = transformedData;
                            observer.next(response);
                        } else {
                            observer.next(response);
                        }

                    } catch (e) {
                        if (this.platform.is('android')) {
                            response = JSON.parse(response);
                            observer.next(response);
                        } else {
                            observer.next(response);
                        }
                    }
                }
            );
        });
    }


    public unsubscribeFromProperty(property: string): Promise<any> {
        return new Promise((resolve, reject) => {
            window.cordova.plugins.TWRGino.unsubscribeFromProperty(
                property,
                result => {
                    try {
                        console.log(result);
                        // const response = JSON.parse(result);

                        resolve(result);
                    } catch (e) {
                        const response = result;
                        resolve(response);
                    }
                },
                err => {
                    console.error(err);
                    try {
                        // const response = JSON.parse(err);
                        reject(err);
                    } catch (e) {
                        const response = err;
                        reject(response);
                    }
                }
            );
        });
    }

    public getTractorVin(): Observable<any> {
        return Observable.create(observer => {
            window.cordova.plugins.TWRGino.getTractorVIN(
                result => {
                    try {

                        if (this.platform.is('android')) {
                            let parsedResult = JSON.parse(result);

                            if (parsedResult.result) {
                                let transformedData: ISubscriptionModel = {
                                    result: {
                                        propertyName: parsedResult.result.property,
                                        propertyValue: parsedResult.result.stringValue
                                    }
                                }
                                let new_result = transformedData;
                                return observer.next(new_result);
                            }
                        } else {
                            if (result.result) {
                                observer.next(result);
                            }
                        }
                    } catch (e) {
                        const response = result;
                        observer.next(response);
                    }
                }
            );
        });
    }
}
