import { Component, OnInit, Input, OnDestroy, NgZone } from '@angular/core';
import { ModalController, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Observable, Subscription, of, EMPTY } from 'rxjs';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { IDealer } from 'src/app/models/IDealer.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, tap, map, catchError, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Plugins } from '@capacitor/core';
import { StorageKey } from 'src/app/models/StorageKey.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { MapDataService } from '../../shared/dealer-map/map-data.service';
import { APIResultState } from 'src/app/models/APIResultState.model';
const { Storage } = Plugins;

@Component({
  selector: 'app-list-dealers',
  templateUrl: './list-dealers.page.html',
  styleUrls: ['./list-dealers.page.scss'],
})
export class ListDealersPage implements OnInit, OnDestroy {
  dealers$: Observable<any>;

  @Input() pageSource: 'my-dealer' | 'promotion-detail' | 'dashboard';
  @Input() promoId: string;

  dealerList: IDealer[] = [];
  temp: IDealer[] = [];
  searching: boolean = false;
  searchedItemNotFound: boolean = false;

  searchTerm = new FormControl();

  searchTerm$: Subscription = this.searchTerm.valueChanges.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap((term) => {
      if (term) {
        if (term.length > 0) {
          this.searching = true;

          if (this.pageSource === 'promotion-detail') {
            let itemsFiltered = this.temp.filter(item => {
              return item.company_name.toLowerCase().indexOf(term.toLowerCase()) > -1;
            });
            this.searchedItemNotFound = itemsFiltered.length > 0 ? false : true;
            this.searching = false;
            this.dealerList = itemsFiltered;
            return of(this.dealerList);
          }
          let countryId = this.profileService.getCurrentUserProfile().profileInfo.country.id;
          return this.dealerService.searchDealerEntries(countryId, term).pipe(
            tap((res) => {
              this.searchedItemNotFound = res.length > 0 ? false : true;
              this.searching = false;
            }),
            map(res => this.dealerList = [...res]),
            catchError((err) => {
              this.searchedItemNotFound = true;
              this.searching = false;
              return of([]);
            })
          )
        } else {
          this.searching = false;
          this.searchedItemNotFound = false;
          this.dealerList = [...this.temp];
          return of(this.dealerList);
        }
      } else {
        this.searching = false;
        this.searchedItemNotFound = false;
        this.dealerList = [...this.temp];
        return of(this.dealerList);
      }
    })
  ).subscribe();

  constructor(
    private authService: AuthService,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private dealerService: DealerService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private mapDataService: MapDataService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

    let dealersList$: Observable<any> = EMPTY;

    if (this.pageSource === 'my-dealer' || this.pageSource === 'dashboard') {
      this.profileService.userProfile.subscribe(
        res => {
          console.log('gedaa', res)
          if (res.state === APIResultState.loaded) {
            let currentCountryId = this.profileService.getCurrentUserProfile().profileInfo.country.id;
            dealersList$ = this.dealerService.getDealersWithinMarket(currentCountryId);
          }
        },
        err => {
          console.error(err)
        }
      )
    }

    if (this.pageSource === 'promotion-detail') {
      dealersList$ = this.dealerService.getDealerListForPromotionDetail(this.promoId)
    }

    this.dealers$ = dealersList$.pipe(
      tap(res => {
        this.ngZone.run(() => {
          this.dealerList = res;
          this.temp = this.dealerList;
        })
      }),
      map(res => res)
    );

  }

  selected(dealer: IDealer) {
    if (dealer.id) {
      this.confirmationAlert(dealer);
    }
  }

  async confirmationAlert(selectedDealer) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('dealer.alertBox.title'),
      message: this.translateService.instant('dealer.alertBox.message', { dealer_name: selectedDealer.company_name }),
      buttons: [
        {
          text: this.translateService.instant('shared.cancel'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('shared.select'),
          role: 'confirm',
          handler: () => this.chooseDealer(selectedDealer)
        }
      ]
    });
    return await alert.present();
  }

  async chooseDealer(selectedDealer: IDealer) {
    let message = this.translateService.instant("shared.wait");
    let loading = await this.loadingCtrl.create({
      message
    });
    loading.present();

    const userId = (await Storage.get({ key: StorageKey.userId })).value;
    if (selectedDealer.id && userId) {

      try {
        await this.dealerService.updateUserDealerById(userId, selectedDealer.id);
        await this.authService.refreshAccessToken();
        this.profileService.getUserProfile();
        await Promise.all([loading.dismiss(), this.modalCtrl.dismiss(null, 'confirm')]);
      } catch (e) {
        await loading.dismiss();
        this.presentToast(this.translateService.instant("editDealer.dealerUpdateMessageFailed"));
      }
    } else {
      console.log('no sufficient params for updating dealer')
    }


  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'dark',
      duration: 5000
    });
    toast.present();
  }

  ngOnDestroy(): void {
    this.searchTerm$.unsubscribe();

  }

  ionViewDidLeave() {
    this.searchTerm.reset();
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
