import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicSelectableModule } from 'ionic-selectable';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpInterceptorService } from './services/interceptors/http-interceptor.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faTractor, faNewspaper, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { Keychain } from '@ionic-native/keychain/ngx';

import { BLE } from '@ionic-native/ble/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserAvatarModule } from './components/user-avatar/user-avatar.module';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageSelectModule } from './components/language-select/language-select.module';
import { FooterModule } from './components/footer/footer.module';
import { ListDealersPageModule } from './pages/my-dealer/list-dealers/list-dealers.module';
import { AddTractorPageModule } from './pages/my-tractors/add-tractor/add-tractor.module';
import { ListDealersPage } from './pages/my-dealer/list-dealers/list-dealers.page';
import { AddTractorPage } from './pages/my-tractors/add-tractor/add-tractor.page';
library.add(faTractor, faNewspaper, faClipboard);

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicSelectableModule,
    UserAvatarModule,
    LanguageSelectModule, 
    ListDealersPageModule,
    AddTractorPageModule,
    FooterModule
  ],
  providers: [
    BLE,
    Keychain,
    PhotoViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ListDealersPage, AddTractorPage]

})
export class AppModule { }