import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {

  avatar: string;

  avatar$: Observable<any>;

  constructor(
    private profileService: ProfileService
  ) { }


  ngOnInit() {
    this.avatar$ = this.profileService.userProfile.pipe(tap(info => {
      if (info.profileInfo && info.profileInfo.avatar) {
        this.avatar = info.profileInfo.avatar;
      }
    })
    )
  }

}
