import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vin-help',
  templateUrl: './vin-help.component.html',
  styleUrls: ['./vin-help.component.scss'],
})
export class VinHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
