import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { IDealerPromotion } from 'src/app/models/IDealerPromotion.model';
import { APIResultState } from 'src/app/models/APIResultState.model';

import { StorageKey } from 'src/app/models/StorageKey.model';
import { switchMap, map, tap } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Plugins
} from "@capacitor/core";
const { Storage } = Plugins;
import * as moment from 'moment';
import { IDealerPromotionDetail, ICampaign } from 'src/app/models/IPromotionDetails.model';
import { IParts } from 'src/app/models/IParts.model';

@Injectable({
  providedIn: 'root'
})
export class DealerPromotionService {
  constructor(
    private profileService: ProfileService,
    private http: HttpClient
  ) { }

  private _dealerPromotions = new BehaviorSubject<{ dealer_promotions: IDealerPromotion[], state: APIResultState }>({ dealer_promotions: [], state: APIResultState.loading });

  get dealerPromotions() {
    return this._dealerPromotions.asObservable();
  }

  init() {
    let dealer_promotions$: Observable<any>;
    let userStatusPromise = Storage.get({ key: StorageKey.user_status });

    let user$ = from(userStatusPromise);
    return user$.pipe(
      switchMap((userStatus) => {
        if (userStatus.value === 'complete' || userStatus.value === 'vin') {
          let dealerId$: Observable<any> = this.profileService.getUserProfileById().pipe(map(res => res.dealer.id));
          return dealerId$.pipe(
            switchMap((dealer_id) => {
              dealer_promotions$ = this.getDealerPromotions(dealer_id);
              return dealer_promotions$;
            })
          )
        }
        if (userStatus.value === 'dealer' || userStatus.value === 'both') {
          dealer_promotions$ = this.getDummyPromotion();
          return dealer_promotions$
        }
      }),
      tap(res => console.log("response of dealer promotions => ", res)),
      map((res) => res.campaigns),
      map((promotions: IDealerPromotion[]) => {
        let mapped_promotion = promotions.map(promotion => this.mapDealerPromotion(promotion));
        return mapped_promotion;
      })
    ).subscribe(
      res => this._dealerPromotions.next({ dealer_promotions: res, state: APIResultState.loaded }),
      err => this._dealerPromotions.next({ dealer_promotions: [], state: APIResultState.error })
    );
  }

  getPromotionDetail(campaign_id) {
    let userStatusPromise = Storage.get({ key: StorageKey.user_status });
    let promotions_details$: Observable<{ campaign: ICampaign, parts: IParts[] }>;
    let user$ = from(userStatusPromise);
    return user$.pipe(
      switchMap((userStatus) => {
        if (userStatus.value === 'complete' || userStatus.value === 'vin') {

          let dealerId$: Observable<any> = this.profileService.getUserProfileById().pipe(map(res => res.dealer.id));
          return dealerId$.pipe(
            switchMap((dealer_id) => {
              promotions_details$ = this.http.get<{ campaign: ICampaign, parts: IParts[] }>(`${environment.apiUrl}/api/v1/campaign/frontend/campaign/${campaign_id}/${dealer_id}`)
              return promotions_details$;
            })
          )
        }
        if (userStatus.value === 'dealer' || userStatus.value === 'both') {
          promotions_details$ = this.http.get<{ campaign: ICampaign, parts: IParts[] }>(`${environment.apiUrl}/api/v1/campaign/dummycampaign/${campaign_id}`);
          return promotions_details$;
        }
      }),
      tap((res) => {
        console.log('promotion details before mapping', res);
      }),
      map((res) => this.mapDealerPromotionDetail(res.campaign, res.parts))
    )
  }

  getDealerPromotions(dealer_id) {
    return this.http.get(`${environment.apiUrl}/api/v1/campaign/frontend/${dealer_id}`);
  }

  promoViewCount(campaign_id) {
    return this.http.get(`${environment.apiUrl}/api/v1/campaign/view/${campaign_id}`);
  }

  takePart(dealer_id, campaign_id) {
    return this.http.get(`${environment.apiUrl}/api/v1/campaign/takepart/${campaign_id}/${dealer_id}`);
  }

  getDummyPromotion() {
    return this.http.get(`${environment.apiUrl}/api/v1/campaign/dummycampaign`);
  }

  increasePromoViewCount(promoId: string) {
    // /api/v1/campaign/view/:campaign_id
    return this.http.get(`${environment.apiUrl}/api/v1/campaign/view/${promoId}`);
  }



  mapDealerPromotion(promotion) {
    return <IDealerPromotion>{
      _id: promotion._id,
      campaign_end_date: moment(promotion.campaign_end_date).format("DD-MM-YYYY"),
      campaign_start_date: moment(promotion.campaign_start_date).format("DD-MM-YYYY"),
      description: promotion.description,
      name: promotion.name,
      participant_id: promotion.participant_id,
      preview_image: promotion.preview_image ? `${environment.apiUrl}/${promotion.preview_image}` : '',
      type: promotion.type
    }
  }

  mapDealerPromotionDetail(campaign: ICampaign, parts: IParts[]) {
    console.log('campaign ko data', campaign, parts);
    return <IDealerPromotionDetail>{
      promotion: {
        _id: campaign._id,
        campaign_end_date: moment(campaign.campaign_end_date).format("DD-MM-YYYY"),
        campaign_start_date: moment(campaign.campaign_start_date).format("DD-MM-YYYY"),
        content: campaign.content,
        description: campaign.description,
        discount: campaign.discount,
        discount_or_net_price: campaign.discount_or_net_price,
        name: campaign.name,
        participant_id: campaign.participant_id,
        preview_image: campaign.preview_image ? `${environment.apiUrl}/${campaign.preview_image}` : '',
        type: campaign.type,
        link: campaign.link,
        pdf: campaign.pdf
      },
      parts: parts.map((part) => this.mapPartsData(part))
    }
  }

  mapPartsData(res) {
    return <IParts>{
      _id: res._id,
      image: res.image ? `${environment.apiUrl}/${res.image}` : 'assets/imgs/placeholder.jpg',
      currency: res.currency,
      description: res.description,
      isUsd: res.currency.toLowerCase() === 'eur' ? false : res.currency.toLowerCase() === 'usd' ? true : false,
      discount: res.discount,
      net_price: res.net_price
    }
  }
}
