import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController, IonInput, PopoverController, LoadingController, ToastController, AlertController, IonRouterOutlet, ActionSheetController } from '@ionic/angular';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { VinHelpComponent } from 'src/app/components/vin-help/vin-help.component';
import { MyTractorsService } from 'src/app/services/my-tractors/my-tractors.service';
import { tap, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommercialFamilyPage } from './commercial-family/commercial-family.page';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ITractor } from 'src/app/models/ITractor.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TractorListService } from './tractor-list.service';
import { CameraSource, CameraResultType, Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
const { KTK_OCR_Plugin, Camera } = Plugins;
interface detectedVin {
  id: string;
  vin: string;
  icon: string;
}
enum ImageOrientation {
  Up = "UP",
  Down = "DOWN",
  Left = "LEFT",
  Right = "RIGHT"
}
export interface TextDetection {
  bottomLeft: [number, number];
  bottomRight: [number, number];
  topLeft: [number, number];
  topRight: [number, number];
  text: string;
}

@Component({
  selector: 'app-add-tractor',
  templateUrl: './add-tractor.page.html',
  styleUrls: ['./add-tractor.page.scss']
})
export class AddTractorPage implements OnInit {
  @ViewChild('vinInput', { static: false }) vinInput: IonInput;
  @Input() btmVin: string;
  vinForm: FormGroup;
  pageSource: 'my-tractor' | 'dashboard';

  found: boolean = true;

  tractors$: Observable<any> = this.tractorListService.tractorsInfo;
  detectedVinNumbers: detectedVin[] = [
    // {
    //   "id": "1",
    //   "vin": "ZKDFE902W0TL50164",
    //   "icon": "shield-outline"
    // },
    // {
    //   "id": "2",
    //   "vin": "ZKDFE902W071 501RZ",
    //   "icon": "shield-outline"
    // }
  ];

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private popOverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
    private myTractorService: MyTractorsService,
    private translateService: TranslateService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private authService: AuthService,
    private tractorListService: TractorListService
  ) { }

  ngOnInit() {
    this.vinForm = this.fb.group({
      vin: new FormControl('', [Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)]),
    });
  }

  async presentActionSheet() {

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Change Profile Picture',
      buttons: [
        {
          text: 'Open Gallery',
          handler: () => {
            this.takePicture(CameraSource.Photos);
          }
        },
        {
          text: 'Take Photo',
          handler: () => {
            this.takePicture(CameraSource.Camera);
          }
        },
        {
          text: 'cancel',
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  async takePicture(source: CameraSource) {
    this.detectedVinNumbers = [];
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source
    });
    const loading = await this.loadingCtrl.create({ backdropDismiss: false });

    try {
      await loading.present();

      const textDetectionsResult = await KTK_OCR_Plugin.detectText({ filename: image.path!, orientation: ImageOrientation.Up });
      const textDetections = textDetectionsResult.textDetections;
      await loading.dismiss();

      for (let i = 0; i < textDetections.length; i++) {
        let key: string = textDetections[i].text ? textDetections[i].text.toLowerCase() : "";
        if (key.includes("identification number:") || key.includes("identilication number:") || key.includes("dentilication fumber:") || key.includes("ldentificatton number:")) {
          const detectedVin = textDetections[i + 1];
          let icon = "shield-outline";
          let vin = detectedVin.text;

          let foundVin: detectedVin = {
            id: i + "",
            icon,
            vin
          };

          this.detectedVinNumbers.push(foundVin);
        }
      }

      if (this.detectedVinNumbers.length === 0) {
        return this.presentToast("Could not detect any VIN");
      }
    } catch (e) {
      console.log('error', e)
      await loading.dismiss();
    }


  }

  ionViewDidEnter() {
    if (this.btmVin) {
      this.vinForm.patchValue({ vin: this.btmVin })
    } else {
      console.log('no any vin sent to set')
    }
    setTimeout(() => {
      this.vinInput.setFocus();
    }, 500);
  }

  async helpVin(event) {
    const popover = await this.popOverCtrl.create({
      component: VinHelpComponent,
      event: event,
      translucent: true
    });
    return await popover.present();
  }

  async onSubmit() {
    if (this.vinForm.invalid) return;

    const loading = await this.loadingCtrl.create({ backdropDismiss: false });
    loading.present();

    this.myTractorService.checkVinForUser(this.vinForm.get('vin').value.toUpperCase()).pipe(
      switchMap((res: any) => {
        if (res.message) {
          return this.myTractorService.getProductInfoByVin(this.vinForm.get('vin').value).pipe(
            tap(async (tractor) => {
              await loading.dismiss();
              let tractors = [tractor];
              this.tractorListService.updateTractorsInfo({ tractors, found: true });
            }),
            catchError(async (err) => {
              await loading.dismiss();
              if (err.error.message === 'Vin not found') {
                return this.presentConfirm();
              }
              if (err.error.message === `No product found for ${this.vinForm.get('vin').value} vin`) {
                let message = this.translateService.instant("vin.diffBrandErr", { vinNum: this.vinForm.get('vin').value, brandName: environment.app_name });
                return this.presentToast(message);
              }
            })
          )
        } else {
          console.log('something went wrong', res)
        }
      }),
      catchError(async (err: any) => {
        await loading.dismiss();
        let error;

        if (err.status === 409) {
          error = this.translateService.instant("vin.dupToastErr");
          return this.presentToast(error);
        }
        else {
          error = this.translateService.instant("shared.anonymousError");
          return this.presentToast(error);
        }
      })
    ).subscribe();
  }


  async presentConfirm() {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('vin.vinNotFoundErr', { vinNum: this.vinForm.get('vin').value }),
      buttons: [
        {
          text: this.translateService.instant("shared.cancel"),
          role: 'cancel'
        },
        {
          text: this.translateService.instant("shared.continue"),
          handler: () => this.presentCommercialFamilyModal()
        }
      ]
    });
    return await alert.present();

  }

  async addTractorConfirmation(tractor: ITractor, found: boolean) {
    let product_name = tractor.tech_name;

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("product.productSelectConfirmBox.title"),
      message: this.translateService.instant('product.productSelectConfirmBox.message', { product_name }),
      buttons: [
        {
          text: this.translateService.instant("product.productSelectConfirmBox.cancelBtn"),
          role: 'cancel',
          handler: () => console.log('Cancel clicked')
        },
        {
          text: this.translateService.instant("product.productSelectConfirmBox.selectBtn"),
          handler: () => this.selectTractor(tractor, found)
        }
      ]
    });
    return await alert.present();
  }

  async selectTractor(tractor: ITractor, found: boolean) {

    let messageTranslated = this.translateService.instant("shared.wait");
    const loader = await this.loadingCtrl.create({
      message: messageTranslated
    });
    loader.present();
    let addTractor$: Observable<any>;
    if (found) {
      addTractor$ = this.myTractorService.updateTractor(tractor.vin)
    } else {
      addTractor$ = this.myTractorService.updateTractor(tractor.vin).pipe(withLatestFrom(this.myTractorService.addProduct(tractor.product_code, tractor.vin)))
    }
    addTractor$.pipe(
      tap(async (res) => {
        await this.authService.refreshAccessToken();
        await loader.dismiss();
        this.myTractorService.init();
        await this.modalCtrl.dismiss(null, 'confirm');
      }),
      catchError(async (err) => {
        console.log("error happened while adding tractor", err)
        await loader.dismiss();
        let message = this.translateService.instant('shared.anonymousError')
        return this.presentToast(message);
      })
    ).subscribe();
  }

  async presentCommercialFamilyModal() {
    const chooseCommercialFamilyModal = await this.modalCtrl.create({
      component: CommercialFamilyPage,
      componentProps: {
        vin: this.vinForm.get("vin").value
      },
      animated: true,
      swipeToClose: true,
      presentingElement: await this.modalCtrl.getTop()
    });
    await chooseCommercialFamilyModal.present();
  }


  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'dark',
      duration: 3000
    });
    toast.present();
  }

  scanVin() {
    KTK_OCR_Plugin.detectText()
  }

  selectVin(vin: detectedVin) {
    console.log('selected VIN', vin);
    let vinNumber = vin.vin.replace(/\s+/g, '');
    let selectedVinIcon = "shield-checkmark-outline";
    this.detectedVinNumbers.map((vinInfo) => {
      if (vinInfo.id === vin.id) {
        vinInfo.icon = selectedVinIcon
      } else {
        vinInfo.icon = "shield-outline"
      }
    })
    this.vinForm.patchValue({ vin: vinNumber })
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  ionViewDidLeave() {
    this.vinForm.reset();
  }

}
