import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
const {Browser} = Plugins;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  firstLabel: string;
  secondLabel: string;
  policy: string;
  constructor(
    // private storage: Storage,
    private router: Router,
    // private translate: TranslateService
  ) {}

  public languages = [];
  public selectedLanguage = null;

  checkSelectedLanguage(code) {
    if (code === this.selectedLanguage) {
      return true;
    }
  }
  // changelanguage($event) {
  //   this.storage.set("selectedLanguage", $event.target.value);
  //   window.location.reload();
  // }
  openWebSite() {
    // window.open("https://www.sdfgroup.com/", "_system", "location=yes");
    Browser.open({url: 'https://www.sdfgroup.com/'})
  }
  openPrivacyPolicy($event) {
    this.router.navigate(["privacy-policy"]);
  }
  async ngOnInit() {
  }
}

