// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SupportedApps } from 'src/app/models/SupportedApp.model';
import { SupportedEnvironment } from 'src/app/models/SupportedEnvironment.model';

export const environment = {
  production: false,
  app_name: SupportedApps.mySAME,
  env: SupportedEnvironment.develop,
  bundle_id: "dev.touchwa.sdfsame",
  // app_id: 'fb5d275d-0c2c-4e8b-b19e-500f5432ce00',
  // apiUrl: 'http://157.230.127.220:3000'
  // app_id: '870fa64c-9bee-4c2e-bc47-88ac09135db3',
  // apiUrl: 'https://myapp-api.sdfgroup.com'

  app_id: 'fb5d275d-0c2c-4e8b-b19e-500f5432ce00',
  apiUrl: 'https://staging.api.sdf.touchwa.re',

  //deutzfahr
  // app_id: 'fab9bafd-ff8a-46eb-96ac-9cdbc542fb61',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
