export const defaultLanguage = "en";

export const availableLanguages = [
  {
    code: "en",
    name: "English"
  },
  {
    code: "it",
    name: "Italian"
  },
  {
    code: "fr",
    name: "French"
  },
  {
    code: "de",
    name: "German"
  },
  {
    code: "es",
    name: "Spanish"
  },
  {
    code: "pt",
    name: "Portugese"
  },
  {
    code: "pl",
    name: "Polish"
  },
  {
    code: "tr",
    name: "Turkish"
  },
  {
    code: "nl",
    name: "Netherland"
  }
];
