import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      take(1),
      tap(allowed => {
        if (allowed) {
          return true;
        }
        else {
          // console.log('state ko url', state.url);
          this.router.navigate(['/login'],
            {
              queryParams: {
                returnUrl: state.url
              }
            });
          return false;
        }
      })
    )
  }
}
