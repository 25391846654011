import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, map, pluck, delay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ITiles } from 'src/app/models/ITiles.model';
import { APIResultState } from 'src/app/models/APIResultState.model';

@Injectable({
  providedIn: 'root'
})
export class TilesService {

  constructor(private http: HttpClient) { }


  private INITIAL_STATE: ITiles[] = [
    {
      column: 12,
      image: '',
      name: 'XXXXXXXXXXX',
      text_color: '',
      tile_background_color: ''
    },
    {
      column: 12,
      image: '',
      name: 'XXXXXX',
      text_color: '',
      tile_background_color: ''
    },
    {
      column: 6,
      image: '',
      name: 'XXXXXXXXX',
      text_color: '',
      tile_background_color: ''
    },
    {
      column: 6,
      image: '',
      name: 'XXXXXX XXXXXX',
      text_color: '',
      tile_background_color: ''
    },
    {
      column: 12,
      image: '',
      name: 'XXXXX',
      text_color: '',
      tile_background_color: ''
    }
  ]
  private _tiles = new BehaviorSubject<{ tiles: ITiles[], state: APIResultState }>({ tiles: this.INITIAL_STATE, state: APIResultState.loading });

  tiles = this._tiles.asObservable();


  getAppTiles() {
    return this.http.get<{ tiles: ITiles[] }>(`${environment.apiUrl}/api/v1/appTile/`)
      .pipe(
        pluck('tiles'),
        map((tiles: any) => {
          tiles.map(tile => {
            tile.column = tile.column === 1 ? (tile.column = 6) : (tile.column = 12);
            tile.image = `${environment.apiUrl}/${tile.image}`;
            return tile;
          });
          return tiles;
        })
      )
      .subscribe(
        res => {
          this._tiles.next({ tiles: res, state: APIResultState.loaded })
        },
        err => this._tiles.next({ tiles: null, state: APIResultState.error })
      )
  }

  getLink(id) {
    return this.http.get(`${environment.apiUrl}/api/v1/externallink/frontend/${id}`)
  }

  lastAccessed() {
    return this.http.get(`${environment.apiUrl}/api/v1/auth/appuser/gettimestamp`)
  }

  clearTiles() {
    this._tiles.next({ state: APIResultState.loading, tiles: this.INITIAL_STATE });
  }

}
