import { Component, ViewChild, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { IDealer } from 'src/app/models/IDealer.model';
const { Geolocation } = Plugins;

@Component({
  selector: 'app-dealer-map',
  templateUrl: './dealer-map.page.html',
  styleUrls: ['./dealer-map.page.scss']
})
export class DealerMapPage implements AfterViewInit {
  @ViewChild('mapCanvas', { static: false }) mapElement: any;
  @Output() confirm = new EventEmitter<any>();

  @Input() dealers: IDealer[];


  geoLocationPermission: boolean = true;
  mapLoading: boolean = true;


  constructor() { }

  ngAfterViewInit() {
    this.mapLoading = true;

    Geolocation.getCurrentPosition().then((userLocation) => {
      getGoogleMaps("AIzaSyCBMWGqMQyZCkHrF3x7VomxtOXHXNf8dV0").then((googleMaps) => {
        const mapEle = this.mapElement.nativeElement;

        const map = new googleMaps.Map(mapEle, {
          center: { lat: userLocation.coords.latitude, lng: userLocation.coords.longitude },
          zoom: 12,
          disableDefaultUI: true,
        });

        new googleMaps.Marker({
          position: { lat: userLocation.coords.latitude, lng: userLocation.coords.longitude },
          map,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          }
        });

        this.dealers.map((dealer) => {
          const infoWindow = new googleMaps.InfoWindow();
          const marker = new googleMaps.Marker({
            position: { lat: dealer.latitude, lng: dealer.longitude },
            map,
            animation: googleMaps.Animation.DROP,
            title: dealer.company_name
          });

          marker.addListener('click', () => {
            var div = document.createElement('div');
            div.innerHTML = `
      <ion-button size="large" fill="clear" id="foo">${dealer.company_name}</ion-button>
      <ion-button size="small" fill="clear" id="foo">${dealer.address}</ion-button>
      `;
            div.onclick = () => this.confirm.emit(dealer);
            infoWindow.setContent(div);
            infoWindow.open(map, marker);
          });
        })

        googleMaps.event.addListenerOnce(map, 'idle', () => {
          mapEle.classList.add('show-map');
          this.mapLoading = false;
        });

      })
    }).catch((err) => {
      if (err.code === 1) {
        this.geoLocationPermission = false;
      }
      this.mapLoading = false;
    })

  }

}

function getGoogleMaps(apiKey: string): Promise<any> {
  const win = window as any;
  const googleModule = win.google;
  if (googleModule && googleModule.maps) {
    return Promise.resolve(googleModule.maps);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.41.5`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      const googleModule2 = win.google;
      if (googleModule2 && googleModule2.maps) {
        resolve(googleModule2.maps);
      } else {
        reject('google maps not available');
      }
    };
  })
}

