import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, map, shareReplay } from 'rxjs/operators';
import { ITractor } from 'src/app/models/ITractor.model';

@Injectable({
  providedIn: 'root'
})
export class VinService {
  defaultImage : string = 'assets/imgs/defaulttractor.png';
  constructor(
    private http:HttpClient
  ) { }

  getProductInfo(vinNum){
    return this.http.get(`${environment.apiUrl}/api/v1/vin/product/${vinNum}`).pipe(
      pluck('product')
    )
  }

  getCommercialFamily(){
    return this.http.get(`${environment.apiUrl}/api/v1/product/frontend`).pipe(
      pluck('commercial_families'),
      shareReplay(1)
    );
  }

  getFakeVin() {
    return this.http.get(`${environment.apiUrl}/api/v1/vin/dummy`).pipe(
      pluck('vin', 'vin')
    )
  }

  getProductFromCommercialFamily(commercialFamilyName){
    return this.http.post<{products: any[]}>(`${environment.apiUrl}/api/v1/product/frontend/`,{commercial_family:commercialFamilyName}).pipe(
      pluck('products'),
      map(products => {
        return products.map((product) => {
          return {
            id: product._id,
            commercial_family: product.commercial_family,
            product_code: product.product_code,
            product_family_catalog: product.product_family_catalog,
            tech_name: product.tech_name,
            picture_name: product.picture_name ? `${environment.apiUrl}/${product.picture_name}` : this.defaultImage 
          } as ITractor
        }) 
      })
    )
  }

 
}
