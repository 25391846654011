import { Injectable } from '@angular/core';
import { BehaviorSubject, from, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, pluck, map, catchError, tap } from 'rxjs/operators';
import { StorageKey } from 'src/app/models/StorageKey.model';
import { ICountry } from 'src/app/models/ICountry.model';
import { ICity } from 'src/app/models/ICity.model';
import { APIResultState } from 'src/app/models/APIResultState.model';
import { IDealer } from 'src/app/models/IDealer.model';
import { IUserProfile } from 'src/app/models/IUserProfile.model';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private http: HttpClient
  ) { }

  private INITIAL_DEALER_STATE: IDealer = {
    id: '',
    company_name: '',
    address: '',
    city: '',
    country: '',
    email: '',
    latitude: '',
    longitude: '',
    phone: '',
    sdf_market: ''
  }

  private INTIAL_STATE: IUserProfile = {
    id: '',
    address: '',
    avatar: 'assets/imgs/Profile@2x.png',
    country: {
      id: '',
      code: '',
      name: ''
    },
    city: {
      id: '',
      name: ''
    },
    dob: '',
    email: '',
    name: '',
    surname: '',
    dealer: this.INITIAL_DEALER_STATE,
    notification: null,
    additionalPolicy: null,
    telephone_number: ''
  }

  private _userProfile = new BehaviorSubject<{ profileInfo: IUserProfile, state: APIResultState }>({ profileInfo: this.INTIAL_STATE, state: APIResultState.loading });

  userProfile = this._userProfile.asObservable();

  getCurrentUserProfile() {
    return this._userProfile.value;
  }

  initUserProfile() {
    return from(Storage.get({ key: StorageKey.userId })).pipe(
      switchMap((userId) => {
        return this.http.get(`${environment.apiUrl}/api/v1/users/${userId.value}`).pipe(
          pluck(('user')),
          map((user: any) => ({
            id: user._id,
            address: user.address,
            avatar: user.avatar ? `${environment.apiUrl}/${user.avatar}` : 'assets/imgs/Profile@2x.png',
            country: user.country ? this.mapCountryModel(user.country) : '',
            city: user.city ? this.mapCityModel(user.city) : '',
            dob: user.dob,
            email: user.email,
            name: user.name,
            surname: user.surname,
            telephone_number: user.telephone_number,
            dealer: user.dealer ? this.mapDealers(user.dealer) : '',
            notification: user.notification,
            additionalPolicy: user.additionalPolicy
          } as IUserProfile)),
          catchError((err) => {
            return throwError(err);
          })
        )
      })
    );
  }

  getUserProfile() {
    return this.initUserProfile().subscribe(
      res => this._userProfile.next({ profileInfo: res, state: APIResultState.loaded }),
      err => {
        this._userProfile.next({ profileInfo: null, state: APIResultState.error })
      }
    )
  }

  getUserProfileById() {
    return from(Storage.get({ key: StorageKey.userId })).pipe(
      switchMap((userId) => {
        return this.http.get(`${environment.apiUrl}/api/v1/users/${userId.value}`).pipe(
          pluck(('user')),
          map((user: any) => ({
            id: user._id,
            address: user.address,
            avatar: user.avatar ? `${environment.apiUrl}/${user.avatar}` : 'assets/imgs/Profile@2x.png',
            country: user.country ? this.mapCountryModel(user.country) : '',
            city: user.city ? this.mapCityModel(user.city) : '',
            dob: user.dob,
            email: user.email,
            name: user.name,
            surname: user.surname,
            telephone_number: user.telephone_number,
            dealer: user.dealer ? this.mapDealers(user.dealer) : '',
            notification: user.notification,
            additionalPolicy: user.additionalPolicy
          } as IUserProfile)),
          catchError((err) => {
            console.log("what is the erorr", err);
            return throwError(err);
          })
        )
      })
    )
  }

  updateUserProfile() {
    this.getUserProfile();
  }

  clearUserProfile() {
    this._userProfile.next({ profileInfo: this.INTIAL_STATE, state: APIResultState.loading });
  }


  changePassword(password) {
    return this.http.post(`${environment.apiUrl}/api/v1/auth/appuser/changepassword`, { password });
  }

  updateProfileInfoById(id, user) {
    return this.http.post(`${environment.apiUrl}/api/v1/users/edit/${id}`, user);
  }

  mapCountryModel(rawCountry) {
    return <ICountry>{
      id: rawCountry._id,
      code: rawCountry.code,
      name: rawCountry.name
    }
  }

  mapCityModel(rawCity) {
    return <ICity>{
      id: rawCity._id,
      name: rawCity.name
    }
  }

  mapDealers(data) {
    return <IDealer>{
      id: data._id,
      company_name: data.company_name,
      address: data.address,
      country: data.country,
      city: data.city,
      email: data.email,
      phone: data.phone,
      latitude: data.latitude,
      longitude: data.longitude
    }
  }

}
